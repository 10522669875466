import _ from 'lodash';

export default {
  cpm: [
    {
      title: 'Especialidade 1',
      mode: 'Distribuído',
      cpm: 57.41
    },
    {
      title: 'Especialidade 2',
      mode: 'Distribuído',
      cpm: 8.74
    },
    {
      title: 'Almoço PF',
      mode: 'Acelerado',
      cpm: 9.12
    },
    {
      title: 'Burgers',
      mode: 'Acelerado',
      cpm: 10.56
    },
    {
      title: 'Café da Manhã',
      mode: 'Distribuído',
      cpm: 18.30
    },
  ],
  burndown: {
    budget: {
      percent: 0.433,
      value: 9438
    },
    avg: {
      percent: 0.032,
      value: 289.45
    },
    chart: [
      ["Dia", "Orçamento", { role: 'tooltip' }, "Guia", { role: 'tooltip' }],
      ["29", 78.5, '29/05: 78.5% | R$ 273,3', 75, 'Linha de Burndown: 75%'],
      ["30", 76.3, '30/05: 76.3% | R$ 273,3', 70, 'Linha de Burndown: 70%'],
      ["01", 72.8, '01/05: 72.8% | R$ 273,3', 65, 'Linha de Burndown: 65%'],
      ["02", 67.4, '02/05: 67.4% | R$ 273,3', 60, 'Linha de Burndown: 60%'],
      ["03", 61.1, '03/05: 61.1% | R$ 273,3', 55, 'Linha de Burndown: 55%'],
      ["04", 58.4, '04/05: 58.4% | R$ 273,3', 50, 'Linha de Burndown: 50%'],
      ["05/05", 56.7, '05/05: 56.7% | R$ 273,3', 45, 'Linha de Burndown: 45%']
    ],
  },
  hotspots: [
    {
      title: 'Copacabana',
      audience: 100274
    },
    {
      title: 'Vila Isabel',
      audience: 87400
    },
    {
      title: 'Barra da Tijuca',
      audience: 74912
    },
    {
      title: 'Centro',
      audience: 63056
    },
    {
      title: 'Botafogo',
      audience: 59830
    },
  ],
  hourlyAudience: _.map([...Array(24).keys()], (h) => {
    return _.map([...Array(7).keys()], (d) => {
      return _.random(0, d + h);
    });
  }),
  airtime: {
    total: 601,
    avg: 83
  },
  audience: [
    {lat: 37.786117, lng:-122.440119, audience: 5, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.800886, lng:-122.435959, audience: 3, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.782551, lng: -122.445368, audience: 14, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.782745, lng: -122.444586, audience: 13, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.782842, lng: -122.443688, audience: 7, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.782919, lng: -122.442815, audience: 35, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.782992, lng: -122.442112, audience: 15, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.783100, lng: -122.441461, audience: 5, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.783206, lng: -122.440829, audience: 30, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.783273, lng: -122.440324, audience: 10, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.783316, lng: -122.440023, audience: 3, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.783357, lng: -122.439794, audience: 2, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.783371, lng: -122.439687, audience: 20, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.783368, lng: -122.439666, audience: 3, timestamp: '2020-05-05 18:00:00' },
    {lat: 37.786117, lng:-122.440119, audience: 0.5, timestamp: '2020-05-04 18:00:00' },
    {lat: 37.800886, lng:-122.435959, audience: 3, timestamp: '2020-05-04 18:00:00' },
    {lat: 37.782551, lng: -122.445368, audience: 3, timestamp: '2020-05-04 18:00:00' },
    {lat: 37.782745, lng: -122.444586, audience: 30, timestamp: '2020-05-04 18:00:00' },
    {lat: 37.782842, lng: -122.443688, audience: 7, timestamp: '2020-05-04 18:00:00' },
    {lat: 37.782919, lng: -122.442815, audience: 3, timestamp: '2020-05-03 18:00:00' },
    {lat: 37.782992, lng: -122.442112, audience: 15, timestamp: '2020-05-03 18:00:00' },
    {lat: 37.783100, lng: -122.441461, audience: 5, timestamp: '2020-05-03 18:00:00' },
    {lat: 37.783206, lng: -122.440829, audience: 30, timestamp: '2020-05-03 18:00:00' },
    {lat: 37.783273, lng: -122.440324, audience: 15, timestamp: '2020-05-03 18:00:00' },
    {lat: 37.783316, lng: -122.440023, audience: 3, timestamp: '2020-05-03 18:00:00' },
    {lat: 37.783357, lng: -122.439794, audience: 10, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.783371, lng: -122.439687, audience: 2, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.783368, lng: -122.439666, audience: 3, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.782745, lng: -122.444586, audience: 30, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.782842, lng: -122.443688, audience: 7, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.782919, lng: -122.442815, audience: 3, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.782992, lng: -122.442112, audience: 15, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.783100, lng: -122.441461, audience: 5, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.783206, lng: -122.440829, audience: 30, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.783273, lng: -122.440324, audience: 10, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.783316, lng: -122.440023, audience: 3, timestamp: '2020-05-02 18:00:00' },
    {lat: 37.783357, lng: -122.439794, audience: 8, timestamp: '2020-05-01 18:00:00' },
    {lat: 37.783371, lng: -122.439687, audience: 20, timestamp: '2020-05-01 18:00:00' },
    {lat: 37.783368, lng: -122.439666, audience: 30, timestamp: '2020-05-01 18:00:00' },
    {lat: 37.783357, lng: -122.439794, audience: 2, timestamp: '2020-05-01 18:00:00' },
    {lat: 37.783371, lng: -122.439687, audience: 2, timestamp: '2020-05-01 18:00:00' },
    {lat: 37.783368, lng: -122.439666, audience: 13, timestamp: '2020-05-01 18:00:00' },
    {lat: 37.786117, lng:-122.440119, audience: 50, timestamp: '2020-04-30 18:00:00' },
    {lat: 37.800886, lng:-122.435959, audience: 3, timestamp: '2020-04-30 18:00:00' },
    {lat: 37.782551, lng: -122.445368, audience: 14, timestamp: '2020-04-30 18:00:00' },
    {lat: 37.782745, lng: -122.444586, audience: 13, timestamp: '2020-04-30 18:00:00' },
    {lat: 37.782842, lng: -122.443688, audience: 7, timestamp: '2020-04-30 18:00:00' },
    {lat: 37.782919, lng: -122.442815, audience: 35, timestamp: '2020-04-30 18:00:00' },
    {lat: 37.782992, lng: -122.442112, audience: 15, timestamp: '2020-04-30 18:00:00' },
    {lat: 37.783357, lng: -122.439794, audience: 8, timestamp: '2020-04-29 18:00:00' },
    {lat: 37.783371, lng: -122.439687, audience: 20, timestamp: '2020-04-29 18:00:00' },
    {lat: 37.783368, lng: -122.439666, audience: 30, timestamp: '2020-04-29 18:00:00' },
    {lat: 37.783357, lng: -122.439794, audience: 2, timestamp: '2020-04-29 18:00:00' },
    {lat: 37.783371, lng: -122.439687, audience: 2, timestamp: '2020-04-29 18:00:00' },
    {lat: 37.783368, lng: -122.439666, audience: 13, timestamp: '2020-04-29 18:00:00' },
  ]
}