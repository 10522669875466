<template>
  <div 
    id="demographics" 
  >
    <v-container 
      fluid 
      class="charts-container pa-0"
    >
      <v-row>
        <v-col 
          v-if="cards.gender.toggle"
        >
          <data-card
            :title="cards.gender.title"
            :loading="cards.gender.loading"
            :numbers="cards.gender.numbers"
            :chart="cards.gender.chart"
            :length="cards.gender.chart.length"
            class="gender"
          />
        </v-col>
        <v-col
          v-if="cards.age.toggle"
        >
          <data-card
            :title="cards.age.title"
            :loading="cards.age.loading"
            :chart="cards.age.chart"
            :length="cards.age.chart.length"
            class="age fill-height"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="cards.income.toggle"
        >
          <data-card
            :title="cards.income.title"
            :loading="cards.income.loading"
            :chart="cards.income.chart"
            :numbers="cards.income.numbers"
            :length="cards.income.chart.length"
            class="income"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import services from '@/services';
  import { demographics as getDemographics } from '@/api/dashboard';
  import { sync } from 'vuex-pathify'
  import numeral from 'numeral'
  const moment = require('moment');

    import mock from '@/mock';

  export default {
    name: 'Demographics',

    props: {
      dataKey: {
        type: String,
        default: null
      },
      period: {
        type: Array,
        default: () => null
      },
      scope: {
        type: Number,
        default: 0
      },
      children: {
        type: Array,
        default: () => null
      },
      segment: {
        type: Number,
        default: 0
      },
      segments: {
        type: Object,
        default: () => {}
      },
      user: {
        type: Object,
        default: () => {}
      },
      stored: {
        type: Object,
        default: () => {}
      },
    },

    data: () => ({
      metric: 'demographics',
      cards: {
        gender: {
          toggle: true,
          title: 'Impactos Por Gênero',
          numbers: {
            female: {
              title: 'Mulheres',
              hint: null,
              value: {
                data: 0,
                format: '0[.]0 a',
                color: '#FA8893'
              },
              secondary: {
                data: null,
                format: '0.0 %',
                colored: false
              }
            },
            male: {
              title: 'Homens',
              hint: null,
              value: {
                data: 0,
                format: '0[.]0 a',
                color: '#7E9DF1'
              },
              secondary: {
                data: null,
                format: '0.0 %',
                colored: false
              }
            },
          },
          chart: {
            type: 'PieChart',
            data: null,
            options: {
              chartArea: {
                width: '80%', 
                height: '90%',
                top: '0%'
              },
              height: 320,
              pieHole: 0.5,
              colors: [
                '#7E9DF1',
                '#FA8893',
              ],
              pieSliceTextStyle: {
                color: '#fff',
                fontName: 'inherit',
                fontSize: 14,
                bold: true,
              },
            },
            length: 2
          },
          loading: false,
          empty: false,
        },
        age: {
          toggle: true,
          title: 'Impactos Por Idade x Gênero',
          numbers: {},
          chart: {
            type: 'BarChart',
            data: null,
            options: {
              chartArea: {
                width: '90%', 
                height: '90%',
                top: '8px',
              },
              height: 420,
              isStacked: true,
              colors: [
                '#FA8893',
                '#7E9DF1',
              ],
              hAxis: { 
                ticks: [],
                gridlines: {
                  count: 9,
                  color: '#eee',
                  minSpacing: 20,
                },
                showTextEvery: 1,
              },
            },
            length: 5
          },
          loading: false,
          empty: false,
        },
        income: {
          toggle: true,
          title: 'Impactos Por Classe Social',
          numbers: {},
          chart: {
            type: 'ColumnChart',
            data: null,
            options: {
              chartArea: {
                width: '100%', 
                height: '90%',
              },
              colors: [
                '#38C7C2',
                '#7E9DF1',
              ],
              hAxis: { 
                showTextEvery: 1,
              },
            },
            length: 5
          },
          loading: false,
          empty: false,
        },
      },
      pipeline: {
        demographics: { 
          get: 'getDemographics',
          set: 'setDemographics',
          key: null,
          loading: false,
          updated: false
        },
      },
      source: {
        min: null,
        max: null,
        prototype: {
          demographics: null,
        }
      },
      store: false
    }),

    components: {
      DataCard: () => import('@/components/DataCard'),
    },

    computed: {
      loading () {
        return _.some(this.pipeline, 'loading');
      },
      updated () {
        return _.every(this.pipeline, 'updated');
      },
    },

    watch: {
      dataKey: {
        immediate: true,
        handler (k) {
          if (this.period!=null) {
            this.clearData();
            this.updateView();
          }
          if (!this.store) this.getStoredData();
        }
      },
      $route: {
        immediate: true,
        deep: true,
        handler (route) {
          const charts = _.has(route.query, 'charts') ? _.split(route.query.charts, ',') : []
          if (charts.length>0) {
            _.each(this.cards, (card, key) => {
              card.toggle = _.indexOf(charts, key)>=0;
            })
          }
        }
      },
      updated: {
        immediate: true,
        handler (b) {
          this.$emit('update', b);
        }
      }
    },

    methods: {
      ...services,
      getDemographics,

      setDemographics (key, source, stored) {
        const metric = 'demographics';
        this.pipeline[metric].key = key;
        this.source[key][metric] = source;

        if (_.isNil(source)) source = stored;

        // set gender chart
        const gender = this.cards.gender;
        const M = _.round(source.M);
        const F = _.round(source.F);
        const total = M + F;

        gender.numbers.male.value.data = M;
        gender.numbers.female.value.data = F;

        gender.chart.data = [
          ['Gênero', 'Impactos'],
          ['Homens', { v: M, f: numeral(M).format('0[,]0[.]0 a') }],
          ['Mulheres', { v: F, f: numeral(F).format('0[,]0[.]0 a') }],
        ];

        // set age chart
        const age = this.cards.age;
        const ranges = _.mapValues({'12-17': null, '18-25': null, '26-40': null, '41-55': null, '>55': null}, (range, r) => {
          const male = _.round(source[`${r}-M`]);
          const female = _.round(source[`${r}-F`]);
          return {
            female: female, 
            male: male,
          }
        });
        const values = _.flatten(_.map(ranges, r => [r.male, r.female]));
        const min = _.round(_.min(values));
        let max = _.round(_.max(values));
        max = _.ceil(max, -(max.toString().length-2));

        age.chart.options.hAxis.ticks = _.map([-max, -max*.75, -max/2, -max*.25, 0, max*.25, max/2, max*.75, max], tick => {
          return { v: tick, f: numeral(Math.abs(tick)).format('0[,]0[.]0 a') }
        });
        age.chart.data = [
          ['Idade', 'Mulheres', 'Homens' ],
          ..._.map(ranges, (range, r) => {
            // const rangeTotal = range.female + range.male;
            const male = numeral(range.male).format('0[,]0[.]0 a');
            const pMale = numeral(range.male/total).format('0.0%');
            const female = numeral(range.female).format('0[,]0[.]0 a');
            const pFemale = numeral(range.female/total).format('0.0%');
            return [`${r} anos`, {v:-range.female, f: `${female} (${pFemale})`}, {v: range.male, f: `${male} (${pMale})`}];
          })
        ];

        // set age chart
        const income = this.cards.income;
        
        const levels = _.pick(source, ['A', 'B', 'C', 'D', 'E']);

        income.numbers = _.mapValues(levels, (level, l) => {
          return {
            title: 'Classe '+l,
            hint: null,
            value: {
              data: level,
              format: '0[.]0 a',
              color: '#38C7C2',
              align: 'center'
            },
            secondary: null
          }
        });

        income.chart.data = [
          ['Classe Social', 'Impactos' ],
          ..._.map(levels, (level, l) => ['Classe '+l, {v: level, f: `${numeral(level).format('0[,]0[.]0 a')} (${numeral(level/total).format('0.0%')})` } ])
        ];

        this.pipeline[metric].updated = true;

        this.storeData(source);
      },

      storeData (data) {
        const key = this.dataKey;

        this.$emit('store-data', {
          page: this.metric, 
          key: key, 
          data
        })
      },

      getStoredData () {
        this.store = true;
        const stored = this.stored[this.metric];
        if (this.dataKey==stored.key) {
          this.setDemographics(stored.key, null, stored);
        }
      },

      updateView () {
        this.loadNext();
      },

      clearData () {
        _.each(this.pipeline, p => {
          p.updated = false;
        });
        this.cards.gender.chart.data = null;
        this.cards.gender.numbers.male.value.data = null;
        this.cards.gender.numbers.female.value.data = null;
        this.cards.age.chart.data = null;
        this.cards.income.chart.data = null;
        _.each(this.cards.income.numbers, i => {
          i.value.data = null;
        })
      },

      loadNext () {
        const s = this.dataKey.toString();
        if (!_.has(this.source, s)) {
          this.source[s] = Object.assign({}, this.source.prototype);
        }
        console.log('source', this.source, this.pipeline);
        const source = this.source;
        _.each(this.pipeline, (p, k) => {
          if (source[s][k]==null||p.key!=s) {
            _.each(this.cards, card => {
              card.loading = true;
            });
            p.loading = true;
            this[p.get](
              this.user.auth.token,
              this.dataKey.toString(),
              this.scope, 
              this.children, 
              this.period,
              (key, data) => {
                if (key==s) {
                  this[p.set](key, data);
                }
              },
              (error) => {
                let msg = 'Aguardando resposta da nuvem...';
                if (error.response.status==404) {
                  p.key = s;
                  source[s][k] = [];
                  p.updated = true;
                  msg = _.has(error.response.data, 'message') ? error.response.data.message : 'Não há dados demográficos';
                }else{
                  setTimeout(($) => $.loadNext(), 5000, this);
                }
                this.handleError(error, msg, [
                  true,
                  msg,
                  5000,
                  false
                ]);
              },
              () => {
                p.loading = false;
                _.each(this.cards, card => {
                  card.loading = false;
                });
              }
            );
            return false;
          }
        });
      },

    },

    mounted () {
      
    }
  }
</script>
